import { createRouter, createWebHashHistory } from "vue-router";
import routes from "@/routes/default-routes";
import store from "@/store";
import storeEvents from "./store-events";

const FAKE_SIMPLE_TOKEN = btoa(
  JSON.stringify({
    platform_id: "local",
    user_token: "WTF-SECRET",
    game_id: "loto-astrologer",
  })
);

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next: Function) => {
  console.warn("go", to.fullPath, "from", from.fullPath);
  if (!store.state.ui.isAppLoaded) {
    if (store.state.ui.isAppLoading) {
      return next();
    }
    store.commit("setAppLoading", true);

    /// get token from auth (empty for restore-auth) or use simple_auth
    const urlParams = new URLSearchParams(window.location.search);
    let token: string;

    if (urlParams.has("localapi")) {
      token = FAKE_SIMPLE_TOKEN;
    } else {
      token = urlParams.get("simple_auth") as string;
    }

    let result: any = false;
    // try auth with token
    try {
      result = await store.dispatch("bootstrap", token);
    } catch (err: any) {
      console.error("Bootstrap error: " + (err.text ? err.text : err.message));
      result = false;
    }

    store.commit("setAppLoading", false);

    if (result === false) {
      return next(false);
    }

    // force go to Live tab
    result = { name: "market" };
    store.commit("setIsAppLoaded", false);

    // subscribe to match if any
    let matchKey: string | undefined;
    store.commit("setIsAppLoaded", true);

    // avoid double route enter
    if (result && result.name === to.name) next();
    else next(result);
  } else {
    let result;

    // notify about route change
    if (result !== false) {
      storeEvents.emit("routeChanged");
    }

    next(result);
  }
});

// export function resetRouter(version: UIVersion) {
//   // HACK: replace routes in app router
//   const routes = getUIVersion() === "desktop" ? desktopRoutes : mobileRoutes;
//   const newRouter = createRouter({
//     history: createWebHashHistory(process.env.BASE_URL),
//     routes,
//   });
//   (router as any).matcher = (newRouter as any).matcher; // the relevant part
//   // HACK: reload current route without full page reload
//   const resolved = router.resolve(router.currentRoute.value.path);
//   const next =
//     resolved.name !== "404" && router.currentRoute.value.name !== null
//       ? router.currentRoute.value.name
//       : "/";
//   router.push({ name: next, query: { t: new Date().getTime().toString() } });
// }

export default router;
